.report-image {
  img {
    max-width: 100%;
  }

  .stretched img {
    width: 100%;
  }

  .with-border {
    border: 1px solid var(--border-color);
  }

  .with-background {
    padding: 15px;
    background: var(--content-bg) !important;

    img {
      max-width: 60%;
      margin: 0 auto;
      display: block;
    }
  }
}

.image-tool__description {
  font-size: $small-font-size;
  color: var(--text-muted);
}