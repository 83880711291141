// stylelint-disable declaration-no-important

//@each $color, $value in $theme-colors {
//  @include bg-variant(".bg-#{$color}", $value, true);
//}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-body {
  background-color: var(--body-bg);
}

.bg-container {
  background-color: var(--card-bg);
}

.bg-brand-blue {
  background: $brand-blue;
}

.bg-brand-dark-blue {
  background: $brand-dark-blue;
}

.bg-brand-yellow {
  background: $brand-yellow;
}

@each $color, $value in $theme-colors {
  .bg-soft-#{$color} {
    background-color: lighten($value, 30%) !important;
  }
}

@each $color, $value in $theme-colors {
  .bg-softer-#{$color} {
    background-color: lighten($value, 45%) !important;
  }
}

.bg-img-cover {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50%;
  background-color: var(--img-cover-bg);
}
