.cdx-alert {
  position: relative;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: calc($spacer * 2);

  .pdf-report & {
    padding: 5px 10px;
  }
}

.cdx-alert-info {
  background-color: transparent !important;
  border: 2px solid var(--primary) !important;
  color: var(--primary) !important;

  .pdf-report & {
    border: 1px solid var(--primary) !important;
  }
}

.cdx-alert-warning {
  background-color: transparent !important;
  border: 2px solid var(--warning) !important;
  color: var(--warning-alert-text) !important;
}