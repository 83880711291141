$enable-negative-margins: true;
@import "configuration";
@import "bootstrap";

@import "components/components";
@import "utilities/utilities";

html {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }
}


:root {
  scroll-behavior: auto;
}