.data-list {
  dt {
    font-size: $font-size-xs;
    opacity: 0.5;
    font-weight: $font-weight-normal;
  }
}

.data-list-row {
  display: flex;
  flex-direction: row;

  dl {
    margin-bottom: 0;
    margin-right: calc($spacer * 1.5);

    &:last-child {
      margin-right: 0;
    }
  }

  dd {
    margin-bottom: 0;
  }
}

.data-list-wrappable {
  flex-wrap: wrap;

  dl {
    margin-bottom: calc($spacer / 2);
  }
}

.data-list-small {
  font-size: $font-size-xs;
}

.data-list-2-columns {
  columns: 2;
  dl {
    break-inside: avoid;
  }
}
