// stylelint-disable declaration-no-important

//
// Text
//
.text-muted {
  color: var(--text-muted) !important;

  .pdf-report & {
    color: $text-muted !important;
  }
}
.text-muted-more {
  color: var(--text-muted-more) !important;

  .pdf-report & {
    color: $text-muted-more !important;
  }
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.max-w-96 {
  max-width: 96px;
}

.ellipsis {
  @extend .text-nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}