@each $color, $value in $theme-colors {
  .alert-#{$color} {
    border: 0;
    background-color: var(--#{$color}-alert-bg);
    color: var(--#{$color}-alert-text);

    &.alert-arrow-top {
      &:after {
        color: var(--#{$color}-alert-bg);
      }
    }
  }
}

.alert-with-icon {
  display: flex;

  .icon {
    margin-right: calc($spacer / 2);
  }
}

.alert {
  .popover &:first-child {
    font-size: $font-size-sm;
    padding: calc($spacer / 3) $popover-body-padding-x;
    margin-top: -$popover-body-padding-y;
    margin-left: -$popover-body-padding-x;
    margin-right: -$popover-body-padding-x;
    border-radius: 0;
  }

  .card-body &:first-child {
    margin-top: -$card-spacer-x;
    margin-left: -$card-spacer-x;
    margin-right: -$card-spacer-x;
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;
    border-radius: 0;
  }
}
