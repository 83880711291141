.input-groups-stacked {
  .input-group {
    &:not(:last-child) {
      .input-group-text,
      .form-control {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child) {
      .input-group-text,
      .form-control {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}