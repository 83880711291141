.navbar {
  background-color: var(--navbar-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nav-item {
    position: relative;
    height: $navbar-top-height;
    align-items: center;
    display: flex;

    & + .nav-item {
      margin-left: calc($spacer / 4);
    }
  }

  .nav-link {
    color: var(--nav-link-color);
    font-size: $font-size-sm;
    border-radius: $border-radius;
    padding-top: calc($spacer / 4);
    padding-bottom: calc($spacer / 4);
    display: flex;
    align-items: center;

    &:hover {
      background-color: var(--accent-faded-more);
      color: var(--primary-dark);
    }

    &.active {
      color: var(--primary-dark);

      &::after {
        background-color: var(--primary);
        border-radius: 2px;
        bottom: 0;
        content: '';
        display: block;
        height: 3px;
        left: calc($spacer / 2);
        right: calc($spacer / 2);
        position: absolute;
      }
    }
  }

  .nav-link-large-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 64px;

    &.active {
      background-color: var(--accent-faded-more);
      &:after {
        display: none;
      }
    }
  }
}

.navbar-brand {
  color: var(--nav-link-color);

  .logo-sm {
    display: flex;
  }
}

.navbar-primary,
.navbar-secondary {
  position: sticky;
  z-index: $zindex-sticky;
  top: 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 4px;
    background: var(--navbar-shadow-gradient);
  }

  .dropdown-menu {
    z-index: $zindex-sticky + 1;
  }
}

.navbar-primary {
  border-bottom: 1px solid var(--body-bg);
  height: $navbar-top-height;
}

.navbar-secondary {
  display: flex;
  padding: 0;
  height: $navbar-secondary-height;
  border-bottom: 1px solid var(--body-bg);

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    height: 2px;
    background: linear-gradient(rgba(9, 30, 66, 0) 0px, rgba(9, 30, 66, 0.08) 2px)
  }
}

.navbar-contextual {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //background-color: var(--navbar-bg);
  padding: $spacer;
  display: flex;
  z-index: $zindex-sticky + 1;
}