.loading-spinner {

  display: inline-flex;
}

.loading-spinner-sm {
  .spinner-branded {
    width: 48px;
    height: 48px;
  }
}

.loading-spinner-xs {
  vertical-align: middle;
  .spinner-branded {
    width: 21px;
    height: 21px;
  }
}

.loading-spinner-with-dimming {
  width: 100%;
  height: 100%;
  background: var(--dimmed-bg);
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
}

.spinner-branded {

  $spinner-duration: 1.5s;

  width: 72px;
  height: 72px;

  .logo-circle-1 {
    animation: logo-animation-1 $spinner-duration $transition-default-easing infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  .logo-circle-2 {
    animation: logo-animation-2 $spinner-duration $transition-default-easing infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  .logo-circle-3 {
    animation: logo-animation-3 $spinner-duration $transition-default-easing infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  .logo-circle-4 {
    animation: logo-animation-4 $spinner-duration $transition-default-easing infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  .logo-plus {
    animation: rotate-plus $spinner-duration * 4 $transition-default-easing infinite;
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }

  @keyframes logo-animation-1 {
    0%{
      transform: scale(1);
    }
    3% {
      transform: scale(1.10);
    }
    9%{
      transform: scale(1);
    }
    37%{
      transform: scale(1);
    }
    42%{
      transform: scale(1.10);
    }
    50%{
      transform: scale(1);
    }
  }

  @keyframes logo-animation-2 {
    7% {
      transform: scale(1);

    }
    10%{
      transform: scale(1.10);
    }
    15%{
      transform: scale(1);
    }
  }

  @keyframes logo-animation-3 {
    7%{
      transform: scale(1);

    }
    20%{
      transform: scale(1.10);
    }
    25%{
      transform: scale(1);
    }
  }

  @keyframes logo-animation-4 {
    25%{
      transform: scale(1);

    }
    30%{
      transform: scale(1.10);
    }
    35%{
      transform: scale(1);
    }
  }

  @keyframes rotate-plus {
    0% {
      transform: rotate(0deg);
    }
    12% {
      transform: rotate(0deg);
    }
    15% {
      transform: rotate(90deg);
    }
    37% {
      transform: rotate(90deg);
    }
    40% {
      transform: rotate(180deg);
    }
    62% {
      transform: rotate(180deg);
    }
    65% {
      transform: rotate(270deg);
    }
    87% {
      transform: rotate(270deg);
    }
    90% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.spinner-border-xs {
  width: .7rem;
  height: .7rem;
  border-width: .15em;
}