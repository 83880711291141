$pane-spacer: calc($spacer * 0.75);
$pane-header-height: 40px;

.panes {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    max-width: 100vw;
  }

  & + .panes {
    border-top: 1px solid var(--pane-border-color);
  }
}

.pane {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 1px solid var(--body-bg);
  border-right: 1px solid var(--pane-border-color);
  background-color: var(--body-bg);

  @include media-breakpoint-up(lg) {
    min-width: 350px;
  }
}

.pane-header {
  background-color: var(--body-bg);
  padding: $pane-spacer;
  min-height: $pane-header-height;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pane-footer {
  background-color: var(--navbar-bg);
  padding: $pane-spacer;
  height: $pane-header-height;
  border-top: 1px solid var(--pane-border-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pane-body {
  padding: 0 $pane-spacer $pane-spacer $pane-spacer;

  scrollbar-width: thin;
  scrollbar-color: var(--pane-scrollbar-color) transparent;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: calc($spacer / 2);
    height: calc($spacer / 2);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--pane-scrollbar-color);
    border-radius: 20px;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background: rgba(125,125,125,0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--pane-scrollbar-hover-color);
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--pane-border-color);
  }

  .table {
    tr {
      td,
      th {
        &:first-child {
          padding-left: $spacer;
        }

        &:last-child {
          padding-right: $spacer;
        }
      }
    }
  }
}

.pane-body-scrolled {
  mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 1%);
}

.pane-title {
  font-size: $font-size-sm;
  color: var(--text-muted-more);
  margin: 0;
  font-weight: $font-weight-semibold;
}

.panes-fill-height {
  @include media-breakpoint-up(md) {
    height: calc(100vh - #{$navbar-top-height});
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .pane .pane-body {
    @include media-breakpoint-up(md) {
      height: calc(100vh - #{$navbar-top-height} - #{$pane-header-height});
      overflow: auto;
    }
  }
}

.pane-sidebar {
  background-color: var(--content-bg);
  border-right: 1px solid var(--pane-border-color);
  position: sticky;
  left: 0;
  z-index: $zindex-sticky;

  @include media-breakpoint-up(md) {
    max-width: 225px;
    min-width: 225px;
  }
}

.panes-horizontal-scoller {
  display: flex;
  overflow: auto;
  flex: 1;
  padding: calc($pane-spacer / 2);
  background-color: var(--pane-border-color);
  height: calc(100vh - #{$navbar-top-height});

  .pane {
    border-radius: $border-radius;
    margin-right: calc($pane-spacer / 2);
  }

  .pane-header {
    border-radius: $border-radius $border-radius 0 0;
  }
}
