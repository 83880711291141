:root {
  color-scheme: light dark;

  @each $key, $value in $themeSpecificVariables {
    --#{$key}: #{map-get($value, light)};
  }

  @media (prefers-color-scheme: dark) {

    @each $key, $theme-colors in $themeSpecificVariables {
      --#{$key}: #{map-get($theme-colors, dark)};
    }

  }

  ::selection {
    background-color: var(--editor-text-selected);
  }
}
