.icon {
  &, svg {
    vertical-align: initial;
    display: inline-flex;
    height: 24px;
    width: 24px;
  }
}

@keyframes fade-background {
  0%   { background-color: lightgrey; }
  100% { background-color: transparent; }
}

.icon-hover {
  @extend .text-muted;
  pointer-events: all;

  &:hover {
    @extend .text-body;
  }

  &:active {
    background-color: lightgrey;
    border-radius: 12%;
    animation: fade-background 1s ease-in-out;
  }
}


.icon-sm {
  &, svg {
    height: 18px;
    width: 18px;
  }
}

.icon-xs {
  &, svg {
    height: 11px;
    width: 11px;
  }
}
