body {
  background-color: var(--body-bg);
  color: var(--body-color);
}

a {
  color: var(--link-color);

  &:hover {
    color: var(--link-hover-color);
  }
}

hr {
  border-top: 1px solid var(--border-color);
}

caption {
  color: $table-caption-color;
}

ul {
  padding-inline-start: 1.6rem;
}

strong {
  font-weight: $font-weight-bold;
}
