$sidebar-width: 250px;
$sidebar-left-base-margin: 2.25rem;

.report-sidebar {
  position: sticky;
  top: $navbar-secondary-height;
  padding: 0;
  background-color: var(--body-bg);
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  height: calc(100vh - #{$navbar-secondary-height});
  overflow: auto;
  display: flex;
  flex-direction: column;

  .report-toc-section {
    margin-bottom: $spacer;
  }

  .report-toc-link {
    color: var(--text-muted);
    font-size: 0.9rem;
    padding: calc($spacer / 4);
    margin-bottom: 0;
    flex-grow: 1;
  }

  .report-toc-item {
    color: var(--text-muted);
    font-size: 0.9rem;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .report-toc-item-h2 {
    padding-left: $sidebar-left-base-margin;
  }

  .report-toc-item-h3 {
    padding-left: $sidebar-left-base-margin + $spacer;
  }

  .report-toc-item-h4 {
    padding-left: calc($sidebar-left-base-margin + $spacer * 2);
  }

  .report-toc-item-h5 {
    padding-left: calc($sidebar-left-base-margin + $spacer * 3);
  }

  .report-toc-item-h6 {
    padding-left: calc($sidebar-left-base-margin + $spacer * 4);
  }

  .report-toc-item-h3,
  .report-toc-item-h4,
  .report-toc-item-h5,
  .report-toc-item-h6 {
    &:before {
      left: calc(#{$sidebar-left-base-margin} + 0.5em);
      top: 0.75em;
      background-color: var(--text-muted);
    }
  }

  .report-toc-link,
  .report-toc-section-toggle,
  .report-toc-section-options {
    border-radius: $border-radius-sm;
    display: flex;
    align-items: center;

    &:hover {
      background-color: rgba(125, 125, 125, 0.1);
    }
  }

  .report-sidebar-footer {
    padding: 20px;
    text-align: center;
    z-index: $zindex-fixed;
  }
}

.report-sidebar-body {
  flex-grow: 1;
}

.report-sidebar-left {
  border-right: 1px solid var(--border-color);
}

.report-sidebar-right {
  border-left: 1px solid var(--border-color);
}

.sidebar-item {
  display: flex;
  cursor: pointer;
  line-height: 1.3;
  margin-bottom: calc($spacer / 2);
  padding-right: calc($spacer / 2);
  padding-left: $spacer;
  color: var(--text-muted);
  font-size: 0.9rem;
  justify-content: space-between;
}

.sidebar-item-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--text-muted);
  font-size: 0.9rem;
  padding: calc($spacer / 4);
  margin-bottom: 0;
  flex-grow: 1;
  border-radius: $border-radius-sm;

  &:hover {
    background-color: rgba(125, 125, 125, 0.1);
  }

  .sidebar-item-selected & {
    background-color: $component-active-bg;
    color: $component-active-color;
  }

  .subscript {
    font-size: $font-size-xs;
  }
}
