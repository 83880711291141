.react-dropdown-select {
  border-radius: $input-border-radius !important;
  border-color: var(--dropdown-border-color) !important;
  //font-size: $input-font-size;

  &:focus {
    color: var(--dropdown-link-hover-color);
    background-color: var(--dropdown-link-hover-bg);
    border-color: var(--input-focus-border-color);
    outline: 0;
  }
  &:hover {
    border-color: var(--border-hover-color) !important;
  }
}

.react-dropdown-select-dropdown {
  background-color: var(--dropdown-bg) !important;
  color: var(--dropdown-color) !important;
  border-color: var(--dropdown-border-color) !important;
}

.react-dropdown-select-item {
  border-color: var(--dropdown-border-color) !important;

  &:hover {
    background-color: var(--dropdown-link-hover-bg) !important;
    color: var(--dropdown-link-hover-color) !important;
  }
}

.react-dropdown-select-item-selected {
  background-color: var(--dropdown-link-hover-bg) !important;
  color: var(--dropdown-link-hover-color) !important;
}

.react-dropdown-select-dropdown-handle {
  height: calc($spacer * 2);
  margin-bottom: 0 !important;
}

.react-dropdown-select-option-label {
  font-size: $font-size-xs !important;
}