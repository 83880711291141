$editor-loader-size: 20px;

.codex-editor {
  flex-grow: 1;

  .icon {
    width: initial;
    height: initial;
  }
}

.ce-toolbox__button[data-tool="excel-table"] svg {
  width: 26px;
  height: 26px;
}

.report-block {
  .codex-editor__loader,
  .codex-editor-wrapper {
    display: flex;
    height: auto;
    min-height: 100%;
    width: 100%;
  }

  .codex-editor__loader {
    height: auto;
    min-height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    &:before {
      top: 50%;
      left: 0;
      margin-top: calc($editor-loader-size / -2);
      margin-left: 2px;
    }
  }
}

.report-block-empty {
  position: relative;
  height: 100%;

  &:after {
    $border-dot-size: 4px;
    content: '';
    position: absolute;
    display: block;
    top: calc($border-dot-size / 2);
    left: calc($border-dot-size / -2);
    bottom: $border-dot-size;
    width: $border-dot-size;
    border-left: $border-dot-size dotted $brand-yellow;
  }

  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($brand-yellow, 0.1);
  }
}

.codex-editor__loader {
  &:before {
    animation-duration: 0.5s;
    width: $editor-loader-size;
    height: $editor-loader-size;
    border: 2px solid var(--border-color);
    border-top-color: transparent;
  }
}

.ce-block__content {
  &, &.ce-block--stretched {
    max-width: $editor-block-max-width !important;
  }
}

.ce-toolbar__content {
  max-width: $editor-block-max-width;
}

.ce-toolbar--opened {
  z-index: 1;
}

.ce-inline-toolbar,
.ce-conversion-toolbar,
.ce-conversion-tool__icon,
.ce-settings {
  background-color: var(--dropdown-bg);
  border-color: var(--border-color);
}

.ce-inline-tool,
.ce-conversion-tool,
.ce-inline-toolbar__dropdown,
.ce-settings__button,
.cdx-settings-button {
  color: var(--body-color);

  &:hover {
    background-color: var(--accent-faded-more);
  }
}

.ce-settings__button {
  &:nth-child(n+4) {
    margin-top: 0;
  }
}

.ce-conversion-tool {
  &.ce-conversion-tool--focused {
    background-color: var(--accent-faded-more) !important;
  }
}

.cdx-block {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ce-paragraph {
  line-height: $line-height-base !important;
}

.cdx-list {
  margin-bottom: $spacer !important;

  li {
    padding: 0 !important;
  }
}

.image-tool {
  margin-top: calc($spacer * 1.5);
  margin-bottom: calc($spacer * 1.5);

  .cdx-input, .cdx-button {
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        border-color: var(--input-border-color);
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
  }
}

.ce-block--selected {
  .ce-block__content {
    background-color: var(--editor-text-selected);

    .table {
      background-color: var(--content-bg);
      outline: 1px solid var(--accent-color);
      box-shadow: 0 0 2px var(--accent-faded-more),
      0 0 4px var(--accent-faded-more),
      0 2px 8px var(--accent-faded);
    }
  }
}

.cdx-button {
  background: none;
  color: inherit;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.cdx-settings-button--active {
  color: var(--accent-color);
}

.image-tool--withBackground, .image-tool--loading {
  .image-tool__image {
    background: var(--content-bg) !important;
  }
}

.image-tool--loading {
  .image-tool__image {
    border: 1px solid var(--border-color) !important;
  }
}

.ce-block {
  margin-bottom: $spacer;
}

.ce-block--checked {
  position: relative;
  &:before {
    content: '★';
    pointer-events: none;
    position: absolute;
    display: block;
    top: 0;
    left: calc(100% + 2rem);
    width: 12px;
    padding: 3px 1px;
    bottom: 0;
    border-radius: 0 8px 8px 0;
    color: #fff;
    font-size: 9px;
    background: var(--content-marker-bg);
  }
}

.ce-header {
  padding: 0 !important;
  margin-bottom: 0 !important;
  line-height: $headings-line-height !important;
}

h1.ce-header {
  line-height: $heading-1-line-height !important;
}

.ce-settings--opened {
  .ce-settings__default-zone {
    display: flex !important;
    flex-wrap: wrap;

    .ce-settings__button:last-child {
      margin-right: 0 !important;
    }
  }
}

.ce-settings__button--clone {
}

.ce-settings__button--clone-confirm {
}

//.table {
//  margin-top: .75rem;
//}

.entity-title, .entity-description {
  &[contentEditable="true"][data-placeholder]::before {
    position: absolute !important;
    content: attr(data-placeholder);
    color: #707684;
    font-weight: normal;
    display: none;
  }

  &[contentEditable="true"][data-placeholder]:empty {
    &::before {
      display: block;
    }

    &:focus::before {
      display: none;
    }
  }
}

.table-placeholder {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.entity-title, .entity-description, .table-placeholder {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: var(--input-border-color);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.report-edit {

  $headings: H1, H2, H3, H4, H5, H6;

  #{$headings}, .excel-table {
    position: relative;
    &:after {
      position: absolute;
      left: -$report-edge-padding;
      top: 50%;
      padding-left: calc($spacer / 2);
      display: block;
      font-size: $font-size-xs !important;
      color: var(--text-muted-more);
      transform: translateY(-50%);
    }
  }

  @each $heading in $headings {
    #{$heading} {
      &:after {
        content: '#{$heading}'
      }
    }
  }

  .excel-table {
    &:after {
      top: calc($spacer * 0.75);
      left: calc($spacer * -1.5);
      content: ' ';
      width: 18px;
      height: 18px;
      opacity: 0.4;
      background: url("../inline-svg/microsoft-excel.svg") no-repeat center / contain;
    }
  }
}
