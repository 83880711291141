.report-content {
  padding-top: calc($spacer * 3);
  padding-bottom: calc($spacer * 3);

  :target::before {
    content: '';
    display: block;
    height: $navbar-secondary-height;
    margin-top: -$navbar-secondary-height;
  }
}

.report-content-wrapper {
  display: flex;
}

.report-view-toolbar {
  margin-left: calc($grid-gutter-width / 2);
  position: sticky;
  top: calc($navbar-secondary-height + $spacer / 2);
  left: calc($spacer * 2 + $grid-gutter-width / 2);
  background: var(--body-bg);
  border: 1px solid var(--border-color);
  border-radius: $border-radius-lg;
  padding: calc($spacer / 3) calc($spacer / 2);
  z-index: $zindex-sticky;
  display: inline-flex;
}

.report-editor-sticky-tools-wrapper {
  position: sticky;
  top: $navbar-secondary-height;
  left: 0;
  height: 0;
  width: 0;
  padding-top: calc($spacer / 2);
  z-index: $zindex-sticky;
}

.report-editor-sticky-left {
  left: 0;
  background-color: var(--body-bg);
  mix-blend-mode: multiply;
}

.report-editor-sticky-center {
  left: 50%;

  > * {
    transform: translateX(-50%);
  }
}

.report-editor-sticky-right {
  left: 100%;

  > * {
    transform: translateX(-100%);
  }
}

.report-edit-side-by-side {

  .report-block {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: calc($spacer / 2);
    padding-bottom: calc($spacer / 2);
    max-width: $editor-max-width;
  }

  .table {
    background-color: var(--content-bg);
  }

  .ce-block,
  h1, h2, h3, h4, h5, h6,
  p,
  .table {
    margin-bottom: 0;
  }

  .codex-editor__redactor[style] {
    padding-bottom: 0 !important;
  }

  .ce-block__content {
    margin: 0
  }
}

.report-content-block {

  max-width: $editor-max-width;

  table {
    background-color: var(--content-bg);
  }
}


.report-edit-side-by-side-first {
  .report-block,
  .report-content-block {
    margin-left: auto;
  }
}