.merge-conflict {
  background-color: rgba($orange, 0.1);
}

.merge-source-insert {
  background-color: rgba($green, 0.1);
}

.merge-source-rejected {
  background-color: rgba($gray-400, 0.1);
}

.block-merge-result {
  padding-top: calc($spacer / 2);
  padding-left: calc($spacer / 2);
  padding-right: calc($spacer / 2);
}

.block-merge-result-toolbar {
  background-color: var(--body-bg);
  padding: calc($spacer / 2) $spacer;
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
  margin-top: calc(calc(-2 * $spacer));
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.block-source-left {
  padding-top: calc($spacer / 2);
  padding-right: calc(#{$spacer} * 1.5);
  padding-left: calc($spacer / 2);
  margin-right: calc(var(--bs-gutter-x) / -2)
}

.block-source-right {
  padding-top: calc($spacer / 2);
  padding-left: calc(#{$spacer} * 1.5);
  padding-right: calc($spacer / 2);
  margin-left: calc(var(--bs-gutter-x) / -2)
}

div[class^="block-source-"] {
  height: 100%;
}

.block-merge-col {
  position: relative;
}