$stepper-line-width: 2px;
$stepper-icon-size: 24px;

.stepper {
  display: flex;
  width: 100%;
}

.stepper-vertical {
  flex-direction: column;
  padding-left: calc($stepper-icon-size / 2);

  .stepper-step-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:hover {
      .stepper-step-functions {
        opacity: 1;
      }
    }
  }

  .stepper-step-title {
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
  }

  .stepper-step-title-icon {
    background-color: $primary;
    border-radius: 50%;
    color: $white;
    display: inline-flex;
    margin-left: calc($stepper-icon-size / -2 + $stepper-line-width / 2);
    margin-right: calc($spacer / 2);
    align-items: center;
    font-size: $font-size-sm;
    height: $stepper-icon-size;
    line-height: $stepper-icon-size;
    justify-content: center;
    width: $stepper-icon-size;
  }

  .stepper-step-body {
    border-left: $stepper-line-width dashed var(--border-color);

    .stepper-step-body-wrapper {
      padding: calc($spacer / 2) 0 $spacer calc($spacer * 1.5);
    }
  }

  //.stepper-step-body-collapsed {
  //  position: relative;
  //
  //  .expand-step-indicator {
  //    background-color: $body-bg;
  //    border: 2px solid $border-color;
  //    border-radius: 50%;
  //    color: $text-muted;
  //    height: $icon-size;
  //    left: -($icon-size) / 2 - $border-width;
  //    line-height: $icon-size;
  //    position: absolute;
  //    text-align: center;
  //    width: $icon-size;
  //  }
  //}

  .stepper-step:last-child .stepper-step-body {
    border-left: 0;
  }
}

.stepper-step-future {
  &.card {
    border-style: dashed;
  }
}


.stepper-step-success {
  .stepper-step-title-icon {
    background-color: $success;
  }
}

.stepper-step-active {
  .stepper-step-title-icon {
    background-color: $yellow;
    color: $black;
  }
}
