p {
  .pdf-report & {
    text-align: justify;
    text-align-last: left;
  }
}

.paragraph-bulleted-primary {
  margin-left: calc(1.5 * $spacer);

  .pdf-report & {
      margin-left: $spacer;
  }
}

.paragraph-bulleted-primary:before {
  display: block;
  content:"";
  position: absolute;
  margin-left: calc(-1.5 * $spacer);
  margin-top: calc($spacer / 4);
  width: 0;
  height: 0;
  border-top: calc(0.5 * $spacer) solid transparent;
  border-bottom: calc(0.5 * $spacer) solid transparent;
  border-left: calc(0.5 * $spacer) solid var(--primary);

  .pdf-report & {
    margin-left: calc(-1 * $spacer);
    border-top: calc(0.3 * $spacer) solid transparent;
    border-bottom: calc(0.3 * $spacer) solid transparent;
    border-left: calc(0.3 * $spacer) solid var(--primary);
  }
}

.paragraph-bulleted-secondary {
  margin-left: calc(3 * $spacer);

  .pdf-report & {
    margin-left: calc(2 * $spacer);
  }
}

.paragraph-bulleted-secondary:before {
  display: block;
  content:"";
  position: absolute;
  margin-left: calc(-1.5 * $spacer);
  margin-top: calc($spacer / 4);
  width: 0;
  height: 0;
  border-top: calc(0.5 * $spacer) solid transparent;
  border-bottom: calc(0.5 * $spacer) solid transparent;
  border-left: calc(0.5 * $spacer) solid var(--nav-link-color);

  .pdf-report & {
    margin-left: calc(-1 * $spacer);
    border-top: calc(0.3 * $spacer) solid transparent;
    border-bottom: calc(0.3 * $spacer) solid transparent;
    border-left: calc(0.3 * $spacer) solid var(--nav-link-color);
  }
}