.table {
  --bs-table-bg: transparent !important;
  --bs-table-accent-bg: transparent !important;
  color: var(--body-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: $border-radius-sm;
  border-color: var(--table-border-color);
  margin-top: 1rem;

  th,
  td {
    border-top: 1px solid var(--table-border-color);
    vertical-align: middle;
    position: relative;

    .pdf-report & {
      border-top: none;
    }
  }

  thead {
    th {
      color: var(--text-muted-more);
      font-size: $small-font-size;
      text-transform: uppercase;
      border-top: 0;
      border-bottom: 0;
    }
  }

  tbody + tbody {
    border-top: 2px solid var(--table-border-color);
  }

  &:focus {
    border-color: var(--input-border-color);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .pdf-report & {
    color: $body-color;
    margin-bottom: calc($spacer / 2);
    margin-top: calc($spacer / 2);

    th,
    td {
      //border-top: 1px solid $table-border-color;
    }

    thead {
      th {
        color: $text-muted-more;
      }
    }
  }
}

.table-hover {
  tbody tr {
    &:hover {
      --bs-table-accent-bg: var(--table-hover-bg);
      color: var(--table-hover-color);
    }
  }
}
/*
$table-row-sticky-height: 26px;
$table-row-sticky-height-padded: 33px;
$table-row-sticky-height-extra-compact: 18px;

.table-row-sticky {
  th,
  td {
    @include media-breakpoint-up(lg) {
      top: $navbar-top-height;
      position: sticky;
      z-index: 1;
    }
    background-color: var(--table-header-bg);

    .pdf-report & {
      background-color: $table-header-bg;

      @include media-breakpoint-up(lg) {
        position: static;
        z-index: 0;
        top: unset;
      }
    }
  }
}

.table-row-sticky-secondary {
  th,
  td {
    top: $navbar-top-height + $table-row-sticky-height;

    .pdf-report & {
      top: unset;
    }
  }
}*/

.table-striped {
  > tbody > tr:nth-of-type(#{$table-striped-order}) {
    --#{$variable-prefix}table-accent-bg: var(--table-striped-bg);
    color: var(--table-striped-color);
  }
}

.table-report {
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    padding: $table-cell-padding-xs;
    font-size: $small-font-size;
    border-top: 0;
    border-bottom: 1px solid var(--table-border-color);
    line-height: 1.3em;
    word-break: normal;

    &:first-child {
      text-align: left;
    }

    .pdf-report & {
      border-bottom: none;
    }
  }

  tr.table-report-row-normal th {
    border-top: 1px solid var(--table-border-color);
  }

  &.table-report-normal {
    th,
    td {
      padding: $table-cell-padding;
      font-size: $font-size-base;
    }

    /*.table-row-sticky-secondary {
      td {
        top: $navbar-top-height + $table-row-sticky-height-padded;
      }
    }*/
  }

  &.table-report-extra-compact {
    th,
    td {
      padding: $table-cell-padding-xxs;
      font-size: $font-size-xxs;
    }

    /*.table-row-sticky-secondary {
      td {
        @include media-breakpoint-up(lg) {
          top: $navbar-top-height + $table-row-sticky-height-extra-compact;
        }
      }
    }*/
  }
}

.table-row-header {
  background-color: var(--table-header-bg);

  .pdf-report & {
    background-color: $table-header-bg;
  }
}

.table-row-indented {
  td {
    &:first-child {
      padding-left: $table-cell-padding-xs * 3;
    }
  }
}

.table-calendar {
  tbody {
    th {
      width: 5%;
    }
    td {
      width: 10%;
    }
  }
}

.table-cell-highlighted {
  background-color:  var(--table-accent-bg) !important;

  .pdf-report & {
    background-color: $table-accent-bg;
  }
}

.table-cell-header {
  background-color: var(--table-header-bg) !important;
  font-weight: bold;

  .pdf-report & {
    background-color: $table-header-bg;
  }
}

.table-cell-normal {
  background-color: var(--content-bg) !important;
}

.table-cell-left-border {
  border-left: 1px solid var(--table-border-color);

  .pdf-report & {
    border-left: 1px solid $table-border-color;
  }
}

.table-cell-right-border {
  border-right: 1px solid var(--table-border-color);

  .pdf-report & {
    border-right: 1px solid $table-border-color;
  }
}

.table-cell-number {
  white-space: nowrap;
  text-align: right !important;
  font-variant-numeric: tabular-nums;
}

.table-responsive {
  overflow-x: initial;
  .container & {
    @include media-breakpoint-down(md) {
      overflow-x: auto;
      margin-left: calc($grid-gutter-width / -2);
      margin-right: calc($grid-gutter-width / -2);
      padding-left: calc($grid-gutter-width / 2);
      padding-right: calc($grid-gutter-width / 2);
      width: 100vw;
    }
  }
}

.entity-description {
  font-size: $small-font-size;
  color: var(--text-muted);
  margin-bottom: $spacer;

  .pdf-report & {
    color: $text-muted;
  }
}

.table-clickable-rows {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.table-cell-text-warning {
  color: var(--danger)
}

.table-cell-header,
.table-cell-secondary-header {
  background-color: var(--table-header-bg) !important;
  .pdf-report & {
    background-color: $table-header-bg !important;
  }
}

.resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  cursor: col-resize;
  user-select: none;

  &:hover {
    border-right: 2px solid #0000ff;
  }
}