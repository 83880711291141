.transition-slide-in-left {
  will-change: transform;
  animation: transition-slide-in-left $transition-default-duration both;
}

@keyframes transition-slide-in-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.transition-slide-in-right {
  animation: transition-slide-in-right $transition-default-duration both;
}

@keyframes transition-slide-in-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.transition-slide-up, .transition-slide-down {
  transition: height $transition-default-duration ease-out;
}

.transition-slide-in-down {
  animation-duration: $transition-default-duration;
  animation-name: transition-slide-in-down;
}

@keyframes transition-slide-in-down {
  from {
    opacity: 0;
    transform: translateY(-8px) scale(0.9);
  }

  70% {
    opacity: 1;
    transform: translateY(2px);
  }

  to {
    transform: translateY(0);
  }
}

.slide-out-blurred-top {
  animation: slide-out-blurred-top 500ms cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
}

@keyframes slide-out-blurred-top {
  0% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 0%;
    filter: blur(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    transform-origin: 50% 0%;
    filter: blur(40px);
    opacity: 0;
  }
}

.shake-horizontal {
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

.slide-in-top {
  animation: slide-in-top $transition-default-duration $transition-default-easing both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

