$comments-pane-width: 280px;
$breakpoint-fractional-width: 0.02px;


@mixin enough-room-to-show-comments-aside-in-regular-view() {
  @media (min-width: $editor-max-width + $comments-pane-width) and (max-width: $editor-max-width + $comments-pane-width * 2 ) {
    @content;
  }
}

@mixin enough-room-to-show-comments-aside-in-regular-view-and-editor-centered () {
  @media (min-width: $editor-max-width + $comments-pane-width * 2) {
    @content;
  }
}

.report-section-comments {
  position: relative;
  pointer-events: none;
  flex: 1;
  width: $comments-pane-width;
  z-index: $context-menu-z-index + 1;

  .comment {
    pointer-events: all;
    transition: transform ($transition-default-duration * 3) $transition-default-easing;
    margin-left: 0;
    width: calc(#{$comments-pane-width} - #{$spacer});

    &.comment-sticky::before {
      display: block;
      content:"";
      position: absolute;
      width: calc($spacer / 4);
      top:0;
      bottom: 0;
      left: 0;
      background: rgba($indigo, 0.5);
      border-bottom-left-radius: $card-border-radius;
      border-top-left-radius: $card-border-radius;
    }

    &.comment-company-visible::before {
      display: block;
      content:"";
      position: absolute;
      width: calc($spacer / 4);
      top:0;
      bottom: 0;
      left: 0;
      background: rgba($info, 0.5);
      border-bottom-left-radius: $card-border-radius;
      border-top-left-radius: $card-border-radius;
    }
  }

  &:empty {
    flex: 0;
    width: 0;
    margin-left: 0 !important;
  }
}

.comment-container {
  position: absolute;
  margin-top: -50px;
  transition: top .5s ease 0s;
  padding-bottom: 10px;
  pointer-events: inherit;
  margin-left: $spacer; // card shadow should not be clipped
  right: 0;

  .comment {
    &.activated {
      margin-left: -30px;
      z-index: $context-menu-z-index + 5
    }
  }
}

.comment-content {
  &.comment-collapsed {
    max-height: 100px;
    overflow: hidden;
  }
}

.report-comments-visible {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .container-editor {
    margin-left: 0;
    margin-right: 0;
  }

  &.report-comments-visible-normal-editor {
    max-width: $editor-max-width + $comments-pane-width;

    @include enough-room-to-show-comments-aside-in-regular-view {
      justify-content: center;

      .report-section-comments {
        margin-left: 0;
      }
    }

    @include enough-room-to-show-comments-aside-in-regular-view-and-editor-centered {
      max-width: $editor-max-width;

      .report-section-comments {
        flex: none;
        margin-left: -$comments-pane-width;
      }

      .comment-container {
        transform: translateX(100%);
      }
    }
  }

  .show-comments {
    .comment-highlight {
      &[data-attached=true] {
        background-color: rgba($yellow, 0.4);
      }

      &[data-highlighted] {
        background-color: $yellow;
      }

      &.comment-highlight-sticky {
        &[data-attached=true] {
          background-color: rgba($indigo, 0.3);
        }

        &[data-highlighted] {
          background-color: rgba($indigo, 0.6);
        }
      }
    }
  }
}


.report-with-comments {
  position: relative;
}

.comment {
  .comment-content {
    &.comment-collapsed {
      max-height: 100px;
      overflow: hidden;
    }
  }
}

.comments-sidebar {
  .comment {
    width: 100%;

    &.comment-resolved {
      background: rgba(mix($white, $success, 70%), 0.1);

      &::before {
        display: block;
        content: "";
        position: absolute;
        width: calc($spacer / 4);
        top: 0;
        bottom: 0;
        left: 0;
        background: rgba($success, 0.5);
        border-bottom-left-radius: $card-border-radius;
        border-top-left-radius: $card-border-radius;
      }
    }
  }
}

.show-inline-comments {
  .comment-highlight[data-attached=true] {
    cursor: pointer;
  }
}

.inline-comment.comment-container {
  width: 300px;
  z-index: 2;
  transition: none;
}

.filter-comment-btn {
  @extend .btn;
  vertical-align: top;
}