.nav-link {
  font-weight: $nav-link-font-weight;

  &.btn-link {
    border: 0;
  }
}

.nav-tabs {
  border-bottom: 2px solid var(--border-color);

  .nav-item + .nav-item {
    margin-left: $spacer;
  }

  .nav-link {
    border: 0;
    border-bottom: 2px solid transparent;
    padding-left: 0;
    padding-right: 0;
    color: var(--nav-link-color);

    &.active {
      background-color: transparent;
      border: 0;
      color: var(--primary);
      border-bottom: 2px solid var(--primary);
    }

    &:hover:not(.active) {
      border: 0;
      color: var(--primary);
    }
  }
}

.nav-pills-secondary {
  .nav-link {
    color: var(--body-color);
    font-size: 0.8rem;

    &.active {
      color: var(--primary-dark);
      background-color: var(--accent-faded-more);
    }
  }
}

.nav-settings-tooltip {
  margin-top: 0 !important;
}

.nav-settings-notifications-container {
  width: 20rem;
  max-width: initial;
  font-size: inherit;
}

.nav-settings-notifications {
  max-height: 40rem;
  overflow: auto;
}

.nav-settings-dropdown {
  min-width: 10rem;
}