$black-95: #101010;
$black-93: #121212;
$black-90: #1A1A1A;
$black-87: #1F1F1F;
$black-85: #232323;
$black-80: #333;
$black-75: #2C2C2C;
$black-70: #363636;
$black-65: #414141;
$black-60: #666;
$black-40: #999;
$black-20: #ccc;

$white: #fff;
$gray-100: #f8f9fa;
$gray-150: #f1f3f5;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-450: #B7BEC5;
$gray-500: #A0A8B0;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$brand-blue: #7aa4cf;
$brand-dark-blue: #3971a8;
$blue: saturate($brand-dark-blue, 20%);
$brand-yellow: #ffbb00;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  $brand-yellow;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
    (
      "blue":       $blue,
      "indigo":     $indigo,
      "purple":     $purple,
      "pink":       $pink,
      "red":        $red,
      "orange":     $orange,
      "yellow":     $yellow,
      "green":      $green,
      "teal":       $teal,
      "cyan":       $cyan,
      "white":      $white,
      "gray":       $gray-600,
      "gray-dark":  $gray-800
    ),
    $colors
);

$primary: $blue;
$secondary: lighten($brand-dark-blue, 60%);
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$accent-color: $blue;
$accent-faded: rgba($blue, 0.3);
$accent-faded-more: rgba($blue, 0.1);

$min-contrast-ratio:   3;

$body-bg: $gray-100;
$content-bg: $white;
$link-color: $blue;
$link-hover-color: lighten($primary, 5%);
$link-hover-decoration: underline;
$body-color: #000;
$link-decoration: none;

$popover-header-color: $gray-200;

$text-muted: $gray-600;
$text-muted-more: $gray-500;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-bolder: bolder;


$btn-font-weight: $font-weight-semibold;

$input-bg: $white;
$input-color: $black;
$input-border-color: $gray-400;
$component-active-color: $white;
$component-active-bg: $accent-color;
$component-active-hover: $accent-faded;
$input-focus-border-color: lightyellow;
$input-box-shadow: inset 0 1px 1px rgba($black, .075);

$form-check-input-checked-bg-color: $success;
$form-check-input-checked-border-color: $success;

//$custom-control-indicator-size: 1.4rem;
//$custom-switch-width: $custom-control-indicator-size * 1.75;
//$custom-switch-indicator-border-radius: $custom-control-indicator-size / 2;
//$custom-switch-indicator-size: subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4);

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
      "primary":    $primary,
      "secondary":  $secondary,
      "success":    $success,
      "info":       $info,
      "warning":    $warning,
      "danger":     $danger,
    ),
    $theme-colors
);

$alert-bg-scale: -80%;
$alert-text-scale: 50%;

$alert-success-bg: shift-color($success, $alert-bg-scale);
$alert-success-text: shift-color($success, $alert-text-scale);
$alert-warning-bg: shift-color($warning, $alert-bg-scale);
$alert-warning-text: shift-color($warning, $alert-text-scale);
$alert-danger-bg: shift-color($danger, $alert-bg-scale);
$alert-danger-text: shift-color($danger, $alert-text-scale);
$alert-info-bg: shift-color($info, $alert-bg-scale);
$alert-info-text: shift-color($info, $alert-text-scale);

$dark-alert-bg: $black-90;

$table-header-bg: $gray-200;
$table-accent-bg: $gray-100;
$table-hover-bg: $accent-faded-more;

$card-border-color: rgba($black, .125);
$card-border-hover-color: rgba($black, .25);
$card-title-spacer-y: calc($spacer * 0.75);

$elevate-shadow-10: 0 5px 20px 0 rgba(169, 172, 180, .34);
$elevate-shadow-animation-base: 0 5px 20px 0 rgba(169, 172, 180, 0);

$dropdown-color: $body-color;
$dropdown-bg: $body-bg;
$dropdown-border-color: $border-color;
$dropdown-divider-bg: $gray-200;
$dropdown-link-hover-bg: $yellow;
$dropdown-padding: calc($spacer / 4);
$dropdown-item-padding-x: calc($spacer * 0.75);
$dropdown-item-padding-y: calc($spacer / 3);

$popover-header-padding-x: calc($spacer / 2);
$popover-header-padding-y: calc($spacer / 4);
$popover-border-color: $border-color;

$container-narrow-width: 25rem;
$editor-max-width: 850px;
$editor-block-max-width: calc(#{$editor-max-width} - #{4 * $spacer});
$editor-text-selected: #d5ecff;

$report-edge-padding: calc($spacer * 2);

$dimmed-bg: rgba($modal-backdrop-bg, 0.05);

$themeSpecificVariables: (
  primary:  (light: $primary, dark: $primary),
  primary-light:  (light: lighten($primary, 40%), dark: darken($primary, 20%)),
  primary-dark:  (light: darken($primary, 20%), dark: lighten($primary, 40%)),
  primary-inverted:  (light: $white, dark: $white),
  secondary:  (light: $secondary, dark: $black-80),
  secondary-inverted:  (light: $black, dark: $white),
  success:  (light: $success, dark: $success),
  success-inverted:  (light: $white, dark: $white),
  success-alert-bg:  (light: $alert-success-bg, dark: $dark-alert-bg),
  success-alert-text:  (light: $alert-success-text, dark: $success),
  info:  (light: $info, dark: $info),
  info-inverted:  (light: $white, dark: $white),
  warning:  (light: $warning, dark: $warning),
  warning-alert-bg:  (light: $alert-warning-bg, dark: $dark-alert-bg),
  warning-alert-text:  (light: $alert-warning-text, dark: $warning),
  danger:  (light: $danger, dark: $danger),
  danger-alert-bg:  (light: $alert-danger-bg, dark: $dark-alert-bg),
  danger-alert-text:  (light: $alert-danger-text, dark: $danger),
  info-alert-bg:  (light: $alert-info-bg, dark: $dark-alert-bg),
  info-alert-text:  (light: $alert-info-text, dark: $info),
  body-color: (light: $body-color, dark: $gray-400),
  body-bg: (light: $body-bg, dark: $black-90),
  content-bg: (light: $content-bg, dark: $black-85),
  content-marker-bg: (light: $gray-400, dark: $black-75),
  img-cover-bg: (light: $gray-200, dark: $black-65),
  link-color: (light: $link-color, dark: $link-color),
  link-hover-color: (light: $link-hover-color, dark: $link-hover-color),
  input-bg: (light: $input-bg, dark: $black),
  input-focus-bg: (light: $input-focus-bg, dark: $black),
  input-color: (light: $input-color, dark: $white),
  input-border-color: (light: $input-border-color, dark: $gray-800),
  input-disabled-bg: (light: $input-disabled-bg, dark: $black-90),
  input-focus-border-color: (light: $input-focus-border-color, dark: $yellow),
  input-group-addon-bg: (light: $input-group-addon-bg, dark: $black-90),
  input-group-addon-color: (light: $input-group-addon-color, dark: $gray-600),
  input-group-addon-border-color: (light: $input-group-addon-border-color, dark: $gray-800),
  custom-control-indicator-bg: (light: $body-bg, dark: $gray-800),
  custom-control-indicator-border-color: (light: $form-check-input-border, dark: $gray-800),
  card-bg: (light: $white, dark: $black-85),
  card-border-color: (light: $card-border-color, dark: $black-80),
  card-border-hover-color: (light: $card-border-hover-color, dark: $black-65),
  list-group-hover-bg: (light: $list-group-hover-bg, dark: $black-80),
  list-group-action-active-bg: (light: $list-group-action-active-bg, dark: $black-70),
  nav-link-color: (light: $text-muted, dark: $gray-500),
  navbar-bg: (light: $white, dark: $black-90),
  navbar-shadow-gradient: (
    light: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px),
    dark: linear-gradient($black-95 0px, $black-95  1px, rgba($black-95, 0.5) 1px, rgba($black-95, 0))
  ),
  nav-pills-container-bg: (light: $gray-300, dark: $black-90),
  nav-pills-link-active-color: (light: $nav-pills-link-active-color, dark: $white),
  nav-pills-link-active-bg: (light: $nav-pills-link-active-bg, dark: $black-80),
  border-color: (light: $border-color, dark: $gray-800),
  border-hover-color: (light: darken($border-color, 20%), dark: lighten($gray-800, 20%)),
  text-muted: (light: $text-muted, dark: $gray-500),
  text-muted-more: (light: $text-muted-more, dark: $gray-700),
  progress-bg: (light: $progress-bg, dark: $gray-800),
  elevate-shadow-10: (light: $elevate-shadow-10, dark: 0 5px 20px rgba(0, 0, 0, 0.34)),
  elevate-shadow-animation-base: (light: $elevate-shadow-animation-base, dark: 0 5px 0px rgba(0, 0, 0, 0)),
  table-header-bg: (light: $table-header-bg, dark: $black-80),
  table-accent-bg: (light: $table-accent-bg, dark: $black-75),
  table-border-color: (light: $table-border-color, dark: $black-80),
  table-hover-color: (light: $table-hover-color, dark: $white),
  table-hover-bg: (light: $table-hover-bg, dark: rgba($yellow, 0.2)),
  table-striped-color: (light: $table-striped-color, dark: $gray-400),
  table-striped-bg: (light: $table-striped-bg, dark: $black-75),
  table-button-bg: (light: $gray-150, dark: $black-87),
  pane-border-color: (light: $border-color, dark: $black-95),
  pane-scrollbar-color: (light: $gray-400, dark: $black-65),
  pane-scrollbar-hover-color: (light: $gray-450, dark: $black-60),
  dropdown-color: (light: $dropdown-color, dark: $gray-400),
  dropdown-bg: (light: $dropdown-bg, dark: $black-90),
  dropdown-border-color: (light: $dropdown-border-color, dark: $gray-800),
  dropdown-divider-bg: (light: $dropdown-divider-bg, dark: $gray-800),
  dropdown-link-color: (light: $dropdown-link-color, dark: $gray-500),
  dropdown-link-hover-color: (light: $dropdown-link-hover-color, dark: $yellow),
  dropdown-link-hover-bg: (light: $accent-faded-more, dark: rgba($yellow, 0.2)),
  popover-bg: (light: $popover-bg, dark: $black-85),
  popover-header-bg: (light: --body-bg, dark: $black-80),
  popover-header-color: (light: --body-color, dark: $gray-400),
  popover-body-color: (light: $popover-body-color, dark: $gray-400),
  popover-border-color: (light: $popover-border-color, dark: $gray-800),
  editor-text-selected: (light: $editor-text-selected, dark: rgba($yellow, 0.2)),
  accent-color: (light: $accent-color, dark: $yellow),
  accent-faded: (light: $accent-faded, dark: rgba($yellow, 0.3)),
  accent-faded-more: (light: $accent-faded-more, dark: rgba($yellow, 0.2)),
  dimmed-bg: (light: $dimmed-bg, dark: rgba($white, 0.05)),
  opaque-text: (light: rgba($body-color, 0.5), dark: rgba($white, 0.6)),
);

$font-size-base: 1rem;
$small-font-size: .8em;
$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$headings-font-weight: 600;
$headings-line-height: 1.2;
$heading-1-line-height: 1.1;


$font-size-xs: 0.75rem;
$font-size-xxs: 10px;

$table-cell-padding: 0.25rem 0.5rem;
$table-cell-padding-xs: .3rem;
$table-cell-padding-xxs: .15rem;

$display1-weight: $font-weight-bold;
$display2-weight: $font-weight-bold;
$display3-weight: $font-weight-bold;
$display4-weight: $font-weight-bold;

$type-scale-factor: 1.333;

$h6-font-size: $font-size-base;
$h5-font-size: $h6-font-size * $type-scale-factor;
$h4-font-size: $h5-font-size * $type-scale-factor;
$h3-font-size: $h4-font-size * $type-scale-factor;
$h2-font-size: $h3-font-size * $type-scale-factor;
$h1-font-size: $h2-font-size * $type-scale-factor;

$report-toolbar-height: 64px;

// Navbar
$navbar-top-height: 42px;
$navbar-secondary-height: 64px;
$navbar-expand-breakpoint: lg;
$navbar-collapse-breakpoint: md;

$nav-tabs-border-width: 2px;
$nav-link-font-weight: $font-weight-semibold;

$transition-default-duration: 100ms;
$transition-default-easing: cubic-bezier(0.215, 0.61, 0.355, 1);

$importanceLevels2: (
  level-1: #ea3532,
  level-2: #f74445,
  level-3: #ff6443,
  level-4: #ff8538,
  level-5: #ffa62a,
  level-6: #ffc100,
  level-7: #e3c400,
  level-8: #c0c900,
  level-9: #32af00
);

$importanceLevels: (
  level-1: #ea3532,
  level-2: #f74445,
  level-3: #ff6443,
  level-4: #ff8538,
  level-5: #ffa62a,
  level-6: #ffc100,
  level-7: #e3c400,
  level-8: #c0c900,
  level-9: $gray-300
);

$badge-font-weight: $font-weight-semibold;