.inline-editable {
  width: 100%;
  span:first-child {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid var(--input-border-color);
    }
  }

  input,
  .inline-editable-mock-input {
    display: block;
    width: 100%;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
    min-height: calc(1.5em + #{calc(2 * $input-border-width)});
    padding: 0.25em 2px;
    @include font-size($input-font-size-sm);
    line-height: 1em;
    @include border-radius($input-border-radius-sm);
  }

  .inline-editable-mock-input {
    border: $input-border-width solid transparent;
  }

  span.inline-editable-mock-input {
    white-space: pre-wrap;
  }

  input {
    @extend .form-control;
  }


  span:empty:after {
    content: "\00a0";
    display: inline-block;
    width: 100%;
  }
}