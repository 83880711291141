$btn-xs-padding-y: calc($spacer / 4);
$btn-xs-padding-x: calc($spacer / 4);

.btn-with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  svg {
    &:first-child {
      margin-right: 0.33em;
    }

    &:last-child {
      margin-left: 0.4em;
    }
  }
}

.btn-icon-only {
  display: inline-flex;
  align-items: center;

  &.btn-sm {
    padding-left: $btn-padding-y-sm;
    padding-right: $btn-padding-y-sm;
  }
}

.btn-outline-secondary {
  color: var(--text-muted);
  border: 1px solid var(--border-color);

  &:hover {
    border: 1px solid var(--border-hover-color);
  }

  &:disabled,
  &.disabled {
    color: var(--opaque-text);
  }
}

.btn-secondary {
  background-color: var(--secondary);
  color: var(--text-muted);
  border: 1px solid var(--secondary);

  &:hover {
    color: var(--body-color);
    border: 1px solid var(--secondary);
    background-color: var(--accent-faded-more);
  }
}

.btn-purple {
  background-color: $purple;
  color: $white;
  border: 1px solid $purple;

  &:hover {
    color: $white;
    border: 1px solid $purple;
    background-color: $purple;
  }
}

.btn-outline-purple {
  color: $purple;
  border: 1px solid $purple;

  &:hover {
    background: $purple;
    svg {
      color: white
    }
  }

  &:disabled,
  &.disabled {
    color: var(--opaque-text);
  }
}

.border-purple {
  border-color: $purple !important;
}

.btn-xs {
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  padding-top: $btn-xs-padding-y;
  padding-bottom: $btn-xs-padding-y;
  padding-left: $btn-xs-padding-x;
  padding-right: $btn-xs-padding-x;
  border: 1px solid var(--border-color);
  line-height: 1;

  &.btn-with-icon {
    .icon:first-child {
      margin-right: calc($spacer / 4);
    }
  }
}

.btn-link {
  &:hover {
    text-decoration: underline;
  }
}

.btn-close {
  @media (prefers-color-scheme: dark) {
    filter: invert(1);
  }
}