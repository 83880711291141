.bookmark {
  text-align: center;

  span {
    padding: 0 calc($spacer / 2);
    background: $white;
    display: inline-block;
    color: $gray-600;
    z-index: 1;
    position: relative;
  }

  span:empty {
    border: 1px dashed $orange;
  }

  .dash {
    position: absolute;
    top: 12px;
    width: 100%;
    border-bottom: 1px dashed $gray-500;
  }
}