$context-menu-z-index: 100;

.context-menu {
  position: absolute;
  width: 200px;
  z-index: $context-menu-z-index;
  background-color: var(--dropdown-bg);
  color: var(--dropdown-color);
  border: $dropdown-border-width solid var(--dropdown-border-color);
  box-shadow: var(--elevate-shadow-10);
  padding: $dropdown-padding;
  border-radius: $dropdown-border-radius;
}

.context-menu-anchor {
  position: relative;
}

.context-menu-floating {
  position: fixed;
  top: 150px;
  right: 30px;
}

.context-menu-upwards {
  top: 0;
  transform: translateY(-100%);
}

.context-menu-item {
  display: flex;
  color: var(--dropdown-link-color);
  align-items: center;
  padding: 0.33333rem 0.75rem;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--dropdown-link-hover-color);
    background-color: var(--dropdown-link-hover-bg);
  }
}

.context-menu-section {
  display: flex;
  color: var(--dropdown-link-color);
  align-items: center;
  padding: 0.33333rem 0.75rem;
}

.context-menu-item-text {
  font-size: $font-size-sm;
  line-height: $line-height-base * 0.8;
}

.context-menu-section-title {
  padding: 0.33333rem 0.75rem;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
}

.context-menu-divider {
  border-top: 1px solid var(--dropdown-divider-bg);
}

.context-menu-hover-show-trigger {
  position: relative;
  will-change: transform, opacity;

  &:hover {
    .context-menu {
      opacity: 1;

      &.context-menu-upwards {
        transform: translateY(-100%);
      }
    }
  }

  .context-menu {
    opacity: 0;
    transform: translateY(0);
    transition: transform 100ms ease, opacity 100ms ease;

    &.context-menu-upwards {
      transform: translateY(-50%);
    }
  }
}

.context-menu-color-button {
  border: 1px solid $gray-500;
  border-radius: 3px;

  &:hover {
    border: 1px solid $gray-900;
    background-color: inherit;
  }
}

.context-menu-secondary-item {
  width: 25px;
  height: 25px;
  display: inline-block;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background-color: rgba($gray-500, 0.2);
  }
}