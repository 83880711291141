.report-toc-section-toggle {
  margin-left: calc($spacer * 0.75);
  color: var(--text-muted);
}

.report-toc-section-options {
  color: var(--text-muted-more);

  .dropdown.show & {
    background-color: var(--accent-color);
    color: var(--content-bg);
  }
}

.report-toc-section {
  margin-bottom: calc($spacer * 2);

  &.report-toc-section-in-viewport {
    background-color: rgba($brand-blue, 0.15);
  }
}

.report-toc-item {
  display: flex;
  cursor: pointer;
  line-height: 1.3;
  margin-bottom: calc($spacer / 2);
  padding-right: calc($spacer / 2);

  &.report-toc-item-selected {
    background-color: $gray-200;
  }

  &.locked {
    background-color: rgba($gray-400, .3);
    pointer-events: none;
  }
}

.report-toc-items {
  .report-section-output & {
    @include media-breakpoint-up(md) {
      column-count: 2;
    }
  }
}

.report-toc-item-h1 {
  font-weight: $font-weight-bolder;
  font-size: 1.4em;

  .report-toc-link {
    color: var(--body-color);
  }
}

.report-toc-item-h2 {
  font-size: 1.2em;
}

.report-toc-item-h3 {
  font-size: 1em;
  padding-left: $spacer;
  position: relative;
}

.report-toc-item-h3,
.report-toc-item-h4,
.report-toc-item-h5,
.report-toc-item-h6 {
  &:before {
    content: '';
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: var(--border-color);
    position: absolute;
    left: 0.1em;
    top: 0.5em;
  }

  .report-toc-link {
    font-size: $font-size-xs;
  }
}


.report-toc-item-h4 {
  font-size: 0.9em;
  position: relative;
  padding-left: calc($spacer * 2);

  &:before {
    width: $spacer;
  }
}

.report-toc-item-h5 {
  font-size: 0.8em;
  padding-left: calc($spacer * 3);
  position: relative;

  &:before {
    width: calc($spacer * 2);
  }
}

.report-toc-item-h6 {
  font-size: 0.7em;
  padding-left: calc($spacer * 4);
  position: relative;

  &:before {
    width: calc($spacer * 3);
  }
}

.report-section {
  margin-bottom: calc($spacer * 3);

  ~ .report-section {
    border-top: 1px solid var(--border-color);
    padding-top: calc($spacer * 3);
  }

  .section-diff-button {
    position: sticky;
    top: calc(#{$report-toolbar-height} + 8px);
    margin-left: -40px;
    float: left;

    .spinner-border {
      height: 18px;
      width: 18px;
    }
  }
}

.news-report-title {
  margin-bottom: calc($spacer * 3);
  padding-left: $report-edge-padding;
  padding-right: $report-edge-padding;
}

.report-editor {
  padding-left: $report-edge-padding;
  padding-right: $report-edge-padding;

  .report-edit & {
    padding-top: calc($spacer * 2);
    padding-bottom: calc($spacer * 2);
  }
}

.report-section-table {
  padding-left: $report-edge-padding;
  padding-right: $report-edge-padding;
}

.report-block {
  flex-grow: 1;
  padding-left: $report-edge-padding;
  padding-right: $report-edge-padding;

  .highlighted-word {
    background: rgba($yellow, 0.3);
  }

  .flash-changed-cell {
    animation: flash-changed 3s ease-out 0s 1 alternate;

    @keyframes flash-changed {
      0% {
        background: transparent;
      }
      40% {
        background: rgba($green, 0.4);
      }
      100% {
        background: transparent;
      }
    }
  }
}

.report-block-clone:not(:empty) {
  outline: 1px dashed var(--input-border-color);
  background-color: var(--body-bg);

  .report-content-block {
    opacity: 0.5;
  }
}

.report-block-readonly-section {
  &:hover {
    background-color: var(--body-bg) !important;
  }

  .report-content-block {
    opacity: 0.5;
  }

  .report-edit-side-by-side-first .report-block {
    padding-left: 0;
  }
}


.report-edit-original {
  .report-block {
    padding-top: calc($spacer * 2);
    padding-bottom: calc($spacer * 2);
  }
}

.report-proofreading {
  .bookmark {
    display: none;
  }
}

.report-editor-activate {
  box-shadow: var(--elevate-shadow-animation-base);
  transition: box-shadow ($transition-default-duration * 3) $transition-default-easing;
  will-change: box-shadow;
  padding: calc($spacer * 2);
  min-height: 100%;

  &:hover {
    cursor: pointer;
    box-shadow: var(--elevate-shadow-10);
    background-color: var(--body-bg);
  }
}

.report-editor-activated {
  background-color: var(--content-bg);
  box-shadow: var(--elevate-shadow-10);
}

.report-blocks-row {
  &:hover {
    background-color: var(--accent-faded-more);
  }

  .ce-block__content,
  .report-content-block {
    > *:last-child:not(.cdx-alert) {
      margin-bottom: 0;
      padding-bottom: 0 !important;
    }
  }
}

.report-revision-word-count {
  position: absolute;
  top: 10px;
  right: 20px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-sm;
}

.report-grid {
  width: 100%;
  margin-top: $spacer;
  font-size: $font-size-base;

  .image-tool {
    margin: 0;
  }

  td {
    vertical-align: top;
  }

  td:first-child {
    padding: 5px 5px 5px 0;
  }

  td:last-child {
    padding: 5px 0 5px 5px;
  }

  &.report-grid-bordered {
    border-collapse: collapse;

    td {
      border: 1px solid $gray-400;
      padding: 5px;
    }
  }

  &.report-grid-fixed {
    table-layout: fixed;
  }

  &:last-child {
    margin-bottom: 2rem;
  }
}

.last-updated-notification {
  margin-top: -24px;
  text-align: left;
}

[data-edited] {
  background-color: rgba($gray-400, .3);
  pointer-events: none;
  user-select: none;
  position: relative;

  & > * {
    opacity: 0.5;
  }

  &:after {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: attr(data-edited);
    position: absolute;

    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    white-space: nowrap;
    background-color: #ea3532;
    border-radius: 0.25rem;
  }
}

[data-word-count-excluded] {
  background: repeating-linear-gradient(135deg,
          rgba($gray-200, .5),
          rgba($gray-200, .5) 10px,
          rgba($gray-100, .5) 10px,
          rgba($gray-100, .5) 20px);

  position: relative;

  &:hover:after {
    display: block;
    content: attr(data-word-count-excluded);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    margin-left: 10px;

    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    white-space: nowrap;
    background-color: $brand-blue;
    border-radius: 0.25rem;
  }
}

.report-editor .report-content-block {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
  }
}

.report-edit:not(.report-edit-side-by-side) .report-view {
  padding: 0 2em;
}