$settings-width: 20px;

.editor-grid-table-wrapper {
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;

  &.readonly .editor-grid-wrapper-with-button {
    margin: 0;
    width: 100%;
  }
}

.editor-grid-wrapper-with-button {
  width: calc(100% - #{$settings-width} * 2);
  margin-left: $settings-width;
  margin-top: $settings-width;
  display: flex;
  flex-wrap: wrap;
}

.editor-grid {
  padding-right: 10px;
  width: 100%;
}

.editor-grid-fixed {
  table-layout: fixed;
}

.editor-grid-button {
  background: var(--table-button-bg);
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
  }

  &.disabled {
    color: var(--input-group-addon-color);
    background-color: var(--input-disabled-bg);
    pointer-events: none;
    cursor: none;
  }

  &:hover {
    background: var(--table-hover-bg);
  }
}

.editor-grid-button-horizontal {
  height: $settings-width;
  width: 100%;

  .editor-grid-context-menu {
    top: 0;
  }
}

.editor-grid-button-vertical {
  width: $settings-width;

  .editor-grid-context-menu {
    top: 50%;
  }
}

.editor-grid-bordered {
  .editor-grid-cell {
    border: 1px solid $gray-400;
  }
}

.editor-grid-cell {
  border: 1px solid var(--table-accent-bg);
  vertical-align: top;
  padding: 0 !important;
  position: relative;

  .ce-toolbar__plus {
    left: 5px;
  }

  &.selected {
    &::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: ' ';
      background-color: var(--accent-faded-more);
    }

    box-shadow: inset 0 0 0 1px var(--accent-color);
  }

  [data-tool=table], [data-tool=alert], [data-tool=heading] {
    display: none;
  }

  .cell-content-wrapper {
    padding: 0.8em;
    word-break: break-word;

    &:focus {
      outline: none;
    }
  }

  .editor-grid-column-handle {
    margin-top: -$settings-width;
    height: $settings-width;
    width: calc(100% + 1px);
    border-right: 1px solid var(--border-color);
    padding: 0 !important;
  }

  .editor-grid-row-handle {
    top: -1px;
    left: -$settings-width;
    width: $settings-width;
    position: absolute;
    display: flex;
    height: calc(100% + 1px);
    border-right: 1px solid var(--border-color);
    padding: 0 !important;
  }

  .image-tool {
    margin: 0;
  }

  .codex-editor__redactor {
    margin-right: 0;
  }

  .ce-toolbar__actions {
    right: -15px;
  }

  .codex-editor--narrow {
    .ce-toolbar__content {
      display: flex;
    }
  }
}

tr:not(:first-child) {
  .editor-grid-row-handle {
    border-top: 1px solid var(--border-color);
  }
}

.editor-grid-context-menu {
  width: auto;

  a.disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
