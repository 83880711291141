.list-group-item {
  background-color: var(--card-bg);
  border-color: var(--border-color);
  color: var(--body-color);

  &.active {
    color: var(--primary-inverted);
    background-color: var(--primary);
    border-color: var(--primary);
  }
}

.list-group-item-action {
  color: var(--body-color);

  &:hover,
  &:focus {
    color: var(--body-color);
    background-color: var(--list-group-hover-bg);
  }

  &:active {
    color: var(--body-color);
    background-color: var(--list-group-action-active-bg);
  }
}
