.row {
  pointer-events: none;
  > * {
    pointer-events: all;
  }
}

.row-divided-columns {
  > div:not(:first-child) {
    @include media-breakpoint-up(md) {
      border-left: 1px solid var(--border-color);
    }
  }
}