.print-page-break-inside-avoid {
  @media print {
    page-break-inside: avoid;
  }
}

.print-break-inside-avoid {
  @media print {
    break-inside: avoid;
  }
}