.opacity-0 {
  opacity: 0.33;
}

.opacity-0 {
  opacity: 0;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-hover-parent {
  .opacity-full-on-parent-hover {
    transition: opacity $transition-default-easing $transition-default-duration;
  }

  &:hover {
    .opacity-full-on-parent-hover {
      opacity: 1;
    }
  }
}