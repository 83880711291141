.form-control {
  color: var(--input-color);
  background-color: var(--input-bg);
  border-color: var(--input-border-color);

  &:focus {
    color: var(--input-color);
    background-color: transparent;
    border-color: var(--input-border-color);
  }

  &:disabled,
  &[readonly] {
    background-color: var(--input-disabled-bg);
    color: var(--input-group-addon-color);
  }

  &.is-invalid[type="date"] {
    padding-right: $input-padding-x;
    background-image: none;
  }
}

.required label:after{
  content:" *";
}

a {
  &.disabled {
    color: var(--text-muted) !important;
    pointer-events: none;
  }
}