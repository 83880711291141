.dropdown-menu {
  background-color: var(--dropdown-bg);
  color: var(--dropdown-color);
  border: $dropdown-border-width solid var(--dropdown-border-color);
  box-shadow: var(--elevate-shadow-10);
  padding: $dropdown-padding;
}

.dropdown-item {
  display: flex;
  color: var(--dropdown-link-color);
  align-items: center;

  &:hover,
  &:focus {
    color: var(--dropdown-link-hover-color);
    background-color: var(--dropdown-link-hover-bg);
  }
}

.dropdown-divider {
  border-top: 1px solid var(--dropdown-divider-bg);
}
