$modal-padding-x: calc($spacer * 1.5);
$modal-padding-y: calc($spacer * 1.25);

$modal-sidepanel-header-height: 62px;
$modal-sidepanel-footer-height: 79px;

.modal.show {
  display: block;
}

.modal-header {
  border: 0;
  align-items: center;
  background-color: var(--body-bg);

  .close {
    display: flex;
    align-items: center;
    color: var(--text-muted)
  }
}

.modal-footer {
  border: 0;
  background-color: var(--body-bg);
}

.modal-content {
  background-color: var(--content-bg);
  border-color: transparent;
}

.modal-side-panel {
  margin: 0 0 0 auto;
  box-shadow: 0 -20px 20px rgba($modal-backdrop-bg, 0.8);

  .modal-content {
    border: 0;
    border-radius: 0;
    min-height: 100vh;
  }

  .modal-body {
    @include media-breakpoint-up(md) {
      max-height: calc(100vh - #{$modal-sidepanel-header-height});
      overflow: auto;
    }
  }

  .panes-fill-height {
    @include media-breakpoint-up(md) {
      height: calc(100vh - #{$modal-sidepanel-header-height + $modal-sidepanel-footer-height});
    }
  }

  .modal-header {
    @include media-breakpoint-up(md) {
      height: $modal-sidepanel-header-height;
    }
  }

  .modal-footer {
    @include media-breakpoint-up(md) {
      height: $modal-sidepanel-footer-height;
    }

    border-top: 1px solid $border-color;
    background-color: $body-bg;
    padding-top: $spacer;
    padding-bottom: $spacer;
  }
}

.modal-xxl {
  @include media-breakpoint-up(lg) {
    max-width: 1250px;
  }
}