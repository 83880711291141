.progress {
  background-color: var(--progress-bg);
  height: 5px;
  opacity: 0.66;

  .card-body &:first-child {
    border-radius: $card-border-radius $card-border-radius 0 0;
  }

  .card-body &:last-child {
    border-radius: 0 0 $card-border-radius $card-border-radius;
  }
}
