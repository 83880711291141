.card {
  background-color: var(--card-bg);
  border-color: var(--card-border-color);

  & > .table {
    margin-bottom: 0;

    th,
    td {
      &:first-child {
        padding-left: $card-spacer-x;
      }

      &:last-child {
        padding-right: $card-spacer-x;
      }
    }

    th {
      font-weight: normal;
      padding-top: .5rem;
    }
  }
}

.card-title {
  font-size: $small-font-size;
  font-weight: $font-weight-semibold;
  color: var(--text-muted);
}

.card-shadow {
  box-shadow: var(--elevate-shadow-10);
}

.card-body + .card-body {
  border-top: 1px solid var(--card-border-color)
}

.card-clickable {
  &:hover {
    box-shadow: var(--elevate-shadow-10);
    border: 1px solid var(--card-border-hover-color);
    cursor: pointer;
  }
}

.card-header {
  padding: $card-title-spacer-y $card-spacer-x;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card-header-actions {
  display: flex;
  margin-top: calc($card-title-spacer-y / -2);
  margin-bottom: calc($card-title-spacer-y / -2);
  margin-right: calc($card-spacer-x / -2);
}

.card-body-expandable {
  max-height: 140px;
  overflow: hidden;
  position: relative;

  .card-body-expander {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: $spacer;
    height: calc(5 * $spacer);
    width: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0), var(--content-bg) 70%);
  }

  &.card-body-expanded {
    max-height: unset;

    .card-body-expander {
      background: none;
    }
  }
}

a.card {
  color: var(--body-color);

  &:hover {
    color: var(--body-color);
    text-decoration: none;
  }
}

.card-success {
  border-left: 3px solid $green;
  background-color: rgba($green, 0.1);

  .btn {
    border-color: $text-muted-more;
  }

  &.card-clickable:hover {
    border-left: 3px solid $green;
  }
}

.card-danger {
  border-left: 3px solid $red;

  &.card-clickable:hover {
    border-left: 3px solid $red;
  }
}

.card-info {
  background-color: rgba($blue, 0.08);
}