// This file is not meant for PDF design
// The effects here should only affect the editor / web version of the report
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;600&display=swap');
@import url('https://rsms.me/inter/inter.css');

$report-font-family-base: 'Jost', 'Noto Sans JP', sans-serif;
$report-headings-font-family: 'Jost', 'Noto Sans JP', sans-serif;
$report-table-font-family: 'Inter', 'Noto Sans JP', sans-serif;

$report-font-size-base: 0.91rem; // this size mimics the visual line length of the PDF report

$report-h6-font-size: $report-font-size-base;
$report-h5-font-size: $report-h6-font-size * $type-scale-factor;
$report-h4-font-size: $report-h5-font-size * $type-scale-factor;
$report-h3-font-size: $report-h4-font-size * $type-scale-factor;
$report-h2-font-size: $report-h3-font-size * $type-scale-factor;
$report-h1-font-size: $report-h2-font-size * $type-scale-factor;

$report-font-weight-light: 300;
$report-font-weight-bold: 600;


.report-content {

  p, li, .ce-block {
    font-size: $report-font-size-base;
  }

  p,
  li,
  .ce-block,
  .toc-element,
  .report-toc-item,
  .image-tool__description,
  .entity-description {
    font-family: $report-font-family-base;
  }

  b,
  strong,
  .table-cell-header {
    font-weight: $report-font-weight-bold;
  }

  h1, h2, h3, h4, h5, h6,
  .entity-title,
  .image-tool__title {
    font-family: $report-headings-font-family;
  }

  h1, .h1,
  h3, .h3 {
    font-weight: $report-font-weight-light;
    color: $brand-dark-blue;
  }

  h1, .h1 { font-size: $report-h1-font-size; }
  h2, .h2 { font-size: $report-h2-font-size; }
  h3, .h3 { font-size: $report-h3-font-size; }
  h4, .h4 { font-size: $report-h4-font-size; }
  h5, .h5 { font-size: $report-h5-font-size; }
  h6, .h6 { font-size: $report-h6-font-size; }

  .display-3 {
    font-size: 5 * $report-font-size-base;
  }

  .table {
    font-family: $report-table-font-family;
  }
}