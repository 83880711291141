.popover {
  background-color: var(--popover-bg);
  border-color: var(--popover-border-color);
  color: var(--dropdown-color);
  border: $dropdown-border-width solid var(--dropdown-border-color);
  box-shadow: var(--elevate-shadow-10);
  padding: $dropdown-padding;
}

.popover-header {
  background-color: var(--popover-header-bg);
  color: var(--popover-header-color);
}

.popover-body {
  color: var(--popover-body-color);
  padding: 0.5rem 0.5rem;
}

.popover-menu {
  margin: (-$popover-body-padding-y) (-$popover-body-padding-x);
  padding-top: $dropdown-padding;
}

.popover-menu-item {
  display: flex;
  color: var(--dropdown-link-color);
  align-items: center;
  padding: 0.33333rem 0.75rem;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--dropdown-link-hover-color);
    background-color: var(--dropdown-link-hover-bg);
  }
}

.popover-menu-item-text {
  font-size: $font-size-sm;
  line-height: $line-height-base * 0.8;
}

.bs-popover-bottom {
  .popover-header::before {
    border-bottom: none;
  }
}
