.h1, h1 {
  line-height: $heading-1-line-height;

  .pdf-report & {
    font-weight: $font-weight-light;
    color: $brand-dark-blue;
  }
}

.h2, h2 {
  line-height: 1.2;

  .report-section-output & {
    margin-bottom: 0.5em;
  }
}

.h3, h3 {
  line-height: $headings-line-height;

  .report-section-output & {
    margin-top: 0.25em;
    margin-bottom: 0.5em;
  }

  .pdf-report & {
    color: $brand-blue;
    font-weight: $font-weight-normal;
  }
}

.h4, h4 {
  line-height: $line-height-base;
}

.h5, h5,
.entity-title,
.image-tool__title {
  line-height: $line-height-base;
}

.entity-title,
.image-tool__title {
  @extend h5;
  color: $brand-dark-blue;
  font-weight: $font-weight-normal;
}

.h6, h6 {
  line-height: $line-height-base;

  .report-section-output & {
    margin-top: 1em;
  }
}

.xsmall {
  font-size: $font-size-xs;
}

.xxsmall {
  font-size: $font-size-xxs;
}
