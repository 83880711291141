// stylelint-disable property-blacklist, declaration-no-important

//
// Border
//

.border         { border: $border-width solid var(--border-color) !important; }
.border-top     { border-top: $border-width solid var(--border-color) !important; }
.border-right   { border-right: $border-width solid var(--border-color) !important; }
.border-bottom  { border-bottom: $border-width solid var(--border-color) !important; }
.border-left    { border-left: $border-width solid var(--border-color) !important; }

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: var(--#{$color}) !important;
  }
}
