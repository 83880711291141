.form-select {
  color: var(--input-color);
  background-color: var(--input-bg);
  border-color: var(--input-border-color);
  &:disabled {
    color: var(--input-group-addon-color);
    background-color: var(--input-disabled-bg);
  }

  &.is-invalid {
    background-color: var(--input-bg);
  }
}

.custom-range-with-top-attachment {
  margin-top: -0.75rem;
}

.form-check-input {
  background-color: var(--custom-control-indicator-bg);
  border-color: var(--custom-control-indicator-border-color);
}
