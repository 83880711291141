$opacity: 0.3;
$image-opacity: 0.6;

ins {
  background: rgba($green, $opacity);
  display: inline-block;
  border-radius: 2px;
  position: relative;

  &.mod {
    background: rgba($yellow, $opacity);
    border-radius: 2px;
  }
}

.report-image ins::before {
  display: block;
  content: "";
  position: absolute;
  width: 1rem;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba($green, $image-opacity);
}

del {
  background: rgba($red, $opacity);
  border-radius: 2px;
  display: inline-block;
  position: relative;
}

.report-image del::before {
  display: block;
  content: "";
  position: absolute;
  width: 1rem;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba($red, $image-opacity);
}

.block-moved-away {
  position: relative;

  &:after {
    display: block;
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($red, 0.1);
  }

}

.block-moved-to {
  position: relative;

  &:after {
    display: block;
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($green, 0.1);
  }
}