.common-table {
  table-layout: fixed;

  td {
    word-break: break-word;
  }
}

.report-content-block {
  .common-table {
    td {
      height: 1.6rem;
      min-width: 2rem;
    }
  }
}

.common-table-context-menu {
  position: absolute;
  width: auto;
}

.common-table-wrapper-with-button {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.editor-common-table-cell {
  position: relative;
  min-width: 2rem;

  &.selected {
    &::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: ' ';
      background-color: var(--accent-faded-more);
      pointer-events: none;
    }

    box-shadow: inset 0 0 0 1px var(--accent-color);
  }
}

.common-table-cell-content-wrapper {
  &:focus {
    outline: none;
  }
}

.text-right {
  @extend .text-end
}

.text-left {
  @extend .text-start
}