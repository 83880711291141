$levels: (1, 2, 3, 4, 5, 6, 7, 8, 9);

@each $level in $levels {
  .badge-level-#{$level} {
    background-color: #{map-get($importanceLevels, 'level-' + $level)};
    color: color-contrast(map-get($importanceLevels, 'level-' + $level));
  }
}


$badge-size: 14px;
.badge-circle {
  border-radius: 50%;
  width: $badge-size;
  height: $badge-size;
  font-size: 8px;
  padding: 0;
  line-height: $badge-size;
  display: flex;
  justify-content: center;
  letter-spacing: -0.5px;
  //text-indent: -1px;
}

.badge-circle-sm {
  $circle-size: calc($spacer * 0.83);
  height: $circle-size;
  width: $circle-size;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7px;
  line-height: 1;
  padding: 0;
  letter-spacing: -1px;
  text-indent: -1px;
}

.badge-top-overlap {
  top: 0.25rem !important;
  right: 0.4rem !important;
}

.badge-outline {
  font-size: 0.75rem;
  padding: 0.05rem 0.25rem;
  border: 1px solid var(--border-color);
  border-radius: 0.375rem;
}